
import {Component, Vue, Prop} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Oran from "@/components/inputs/Oran.vue";

@Component({
  components: {Oran, FormWrapper, FormDialog, Tutar, DeleteDialog},
})
export default class TutarAraligiForm extends ObjectInputMixin {
  @Prop() date!: Date;
  oranMiTutarMi !: boolean;

  onClose(){
    this.$emit("onClose");

  }
  load() {
    this.$emit("load");
  }

  save() {

  }
}
