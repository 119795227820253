

import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {BankaEntity} from "@/entity/BankaEntity";
import {KisiEntity} from "@/entity/KisiEntity";

@Component({
  components: {DatabasePicker}
})
export default class BankaPicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId !: number;

}
