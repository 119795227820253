import {NotEntity} from "@/entity/NotEntity";
import {MuvekkilEntity} from "@/entity/MuvekkilEntity";
import {NoterEntity} from "@/entity/NoterEntity";
import {BankaHesabiEntity} from "@/entity/BankaHesabiEntity";
import {BankaHesabiSahibi} from "@/enum/BankaHesabiSahibi";
import {AvukatEntity} from "@/entity/AvukatEntity";
import {FileEntity} from "@/entity/FileEntity";
import {VekaletUcretEntity} from "@/entity/VekaletUcretEntity";
import {TakipEntity} from "@/entity/TakipEntity";

export class VekaletEntity {
    avukatlar: Array<AvukatEntity> = [];
    file: Array<FileEntity> = [];
    id!: number;
    muvekkil_id!: number;
    muvekkiller: Array<MuvekkilEntity> = [];
    takipler : Array<TakipEntity> = [];
    noter!: NoterEntity;
    noter_id!: number;
    notlar: Array<NotEntity> = [];
    varsayilan_banka_hesabi!: BankaHesabiEntity;
    varsayilan_banka_hesabi_id!: number;
    varsayilan_banka_hesabi_sahibi!: BankaHesabiSahibi;
    vekalet_no!: number;
    vekalet_tarihi!: Date;
    vekalet_ucret!: VekaletUcretEntity;
    vekalet_ucret_id!: number;
    yevmiye_no!: string;
}
