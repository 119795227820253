
import {Component, Mixins, Prop} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import BankaHesabiTipiPicker from "@/components/pickers/BankaHesabiTipiPicker.vue";
import BankaPicker from "@/components/pickers/BankaPicker.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import IbanNumarasi from "@/components/inputs/IbanNumarasi.vue";
import {BankaHesabiTipi} from "@/enum/BankaHesabiTipi";
import HesapNumarasi from "@/components/inputs/HesapNumarasi.vue";

@Component({
  components: {
    HesapNumarasi,
    FormWrapper,
    BankaHesabiTipiPicker,
    BankaPicker,
    ParaBirimiPicker,
    IbanNumarasi,
  },
})
export default class BankaHesabiForm extends Mixins(ObjectInputMixin) {
  @Prop() action!: string;
  hesapTipiEnum = BankaHesabiTipi;
  @Prop({}) successOnValidate !: any;
  @Prop() hideButton: any;

  onClose(){
    this.$emit("onClose");

  }
  get localAction() {
    if (this.localValue && this.localValue.id) {
      return "/api/v1/banka-hesabi";
    }
    return this.action;
  }

  protected onValueChange() {
    this.localValue.hesap_tipi = this.hesapTipiEnum.iban;
  }
}
