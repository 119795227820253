

import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";

@Component({})
export default class KisiTipiPicker extends Mixins(SimpleInputMixin) {
  @Prop() required: any;
  kisi_kurum_turu = KisiKurumTuru;

  get isRequired() {
    return this.required || this.required === "";
  }
}
