

import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {SirketTipi, SirketTipiLabel} from "@/enum/SirketTipi";

@Component({
  components: {}
})
export default class SirketTipiPicker extends Mixins(SimpleInputMixin) {
  sirketTipiLabel = SirketTipiLabel;
  sirketTipi = SirketTipi;
}
