
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class SayfaBilgiInfo extends Vue {
  @Prop({default:false}) anaSayfa!:boolean
  @Prop({default:false}) detaySayfa!:boolean
  @Prop() anaSayfaBaslik!:string
  @Prop() detaySayfaBaslik!:string

  onClick(){
    this.$emit('click')
  }

}
