
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import TutarAraligiForm from "@/components/forms/TutarAraligiForm.vue";
import Dates from "@/components/inputs/Dates.vue";
import {AxiosResponse} from "axios";
import {VekaletUcretDonemEntity} from "@/entity/VekaletUcretDonemEntity";

@Component({
  components: {
    Dates,
    TutarAraligiForm,
    FormWrapper,
    FormDialog,
    DeleteDialog,
  }
})
export default class MuvekkilVekaletUcretleriForm extends Mixins(ObjectInputMixin) {
  items: Array<VekaletUcretDonemEntity> = [];
  selectedItem: VekaletUcretDonemEntity = new VekaletUcretDonemEntity();
  headers = [
    {text: "Alt Sınır", value: "alt_sinir", align: "center"},
    {text: "Üst Sınır", value: "ust_sinir", align: "center"},
    {text: "Oran", value: "oran", align: "center"},
    {text: "Tutar", value: "tutar", align: "center"},
    {text: "İşlemler", value: "actions", width: "150", align: "center", sortable: false}
  ];

  onClose(){
    this.$emit("onClose");

  }

  load() {
    this.$emit("load");
  }

  save() {

  }

  // TODO: form çalışmıyor arge yapılması gerekiyor
  loadVekaletUcretleri() {
    this.$http.get("/api/v1/vekalet-ucret-donemi").then((response: AxiosResponse<Array<VekaletUcretDonemEntity>>) => {
      this.items = response.data;
      this.items.reverse();
      this.selectedItem = this.items[0];

      this.items.forEach((donem:any, index:any) => {
        donem.text = donem.baslangic_tarihi ? this.$helper.tarihStr(donem.baslangic_tarihi) : "";
        donem.oranlar?.forEach((oran:any, sayi:any) => {
          let donem = this.items[index]
          if (donem && donem.oranlar) {
            if (donem.oranlar[sayi + 1] && donem.oranlar[sayi] && sayi < donem.oranlar.length - 1) {
              donem.oranlar[sayi].ust_sinir = donem.oranlar[sayi + 1].alt_sinir - 1;
            }
          }
        });
      });
    });
  }
}
