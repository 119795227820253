import {KisiKurumEntity} from "@/entity/KisiKurumEntity";
import {SirketTipi} from "@/enum/SirketTipi";

export class KurumEntity extends KisiKurumEntity{
    sirket_adi!: string;
    vergi_no!: number;
    vergi_dairesi!: string;
    ticaret_sicil_no!: number;
    mersis_no!: string;
    detsis_no!:string;
    sirket_tipi: SirketTipi = SirketTipi.ozel;
    is_mts_kurumu!: boolean | null;
    uyap_kisi_kurum_id!: number;
}
