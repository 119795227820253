
import {Component, Mixins, Prop} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import {KurumSorgulamaTalep} from "@/plugins/uyap-plugin/uyap/KurumSorgulama";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  directives: {
    mask
  },
})
export default class VergiNumarasi extends Mixins(SimpleInputMixin) {
  @Prop({}) value!: number;
  @Prop() required!: any;
  @Prop() uyapSorgula !: string | boolean;
  @Prop() readonly !: string | boolean;
  @Prop({default:'Vergi Kimlik No'}) label !: string
  @Prop({default:'Vergi Kimlik No Giriniz'}) placeHolder !: string;


  mask = "###########";
  localValue = this.value ? this.value.toString() : null;

  rules = [
    (value: any) => {
      return (!this.isRequired || !!value) || "Lütfen bu alanı doldurun."
    },
    (value: any) => {
      return (!this.value || value == "" || [0, 10, 11].includes(value?.toString().length)) || "10 veya 11 karakter olmalıdır.";
    },
    (value: any) => {
      if (value?.toString().length == 11)
        return (!this.value || value === "" || this.$helper.tcKimlikNoValidate(value)) || "Tc No veya Vergi Kimlik No doğru değil."
      else
        return true;
    },
  ];

  get isRequired() {
    return this.required || this.required === "";
  }

  get isReadonly() {
    return this.readonly || this.readonly === "";
  }

  get isUyapSorgulaButton() {
    return this.uyapSorgula || this.uyapSorgula === "";
  }

  async sorgula() {
    if (this.localValue) {
      let talep: KurumSorgulamaTalep = {vergiNo: this.localValue,mersisNo:'',kurumAdi:'',detsisNo:''}
      let cevap = await this.$uyap.KurumSorgulama().run(talep);
      this.$emit('uyap', cevap);
    }
  }

  validate(e: any) {
    this.$helper.onlyNumber(e);
    this.$emit("value", this.localValue);
  }
}
