

import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import SirketTipiPicker from "@/components/pickers/SirketTipiPicker.vue";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";
import {SirketTipi} from "@/enum/SirketTipi";
import AdresList from "@/components/lists/AdresList.vue";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {KurumSorgulamaCevap, KurumSorgulamaTalep} from "@/plugins/uyap-plugin/uyap/KurumSorgulama";
import IbanNumarasi from "@/components/inputs/IbanNumarasi.vue";
import BankaHesabiList from "@/components/lists/BankaHesabiList.vue";
import VergiNumarasiKurumForm from "@/components/inputs/VergiNumarasiKurumForm.vue";

@Component({
  components: {
    VergiNumarasiKurumForm,
    AdresList,
    FormWrapper,
    SirketTipiPicker,
    VergiNumarasi,
    IbanNumarasi,
    BankaHesabiList,
  },
})
export default class KurumForm extends Mixins(ObjectInputMixin) {
  @Prop() action !: string;
  @Prop() muvekkilMi !: boolean;
  @Prop() hideButton!: any;
  @Prop() isAddressEmpty !: boolean;
  @Ref('formWrapper') formWrapper!: FormWrapper;
  sirketTipi = SirketTipi;

  get isHideButton() {
    return this.hideButton || this.hideButton === "";
  }

  get sirketAdiRule() {
    if (this.localValue.sirket_adi) {
      if (this.localValue.sirket_adi.match(/["'<>&]/g) == null)
        return true;
      else
        return `Yasak karakter. ", ', <, >, & karakterleri kullanılamaz.`;
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  onVergiNumarasiChange() {
    if (this.localValue.vergi_no.length == 10 || this.localValue.vergi_no.length == 11) {
      this.$http.get('/api/v1/kurum/filter?keyword=' + this.localValue.vergi_no).then((response) => {
        if (response.data.length > 0)
          this.localValue = response.data[0];
        this.input();
      });
    } else
      this.input();
  }

  async sorgula(data: KurumSorgulamaTalep) {
    let cevap = await this.$uyap.KurumSorgulama().run(data);
    this.uyapSorgu(cevap);
  }

  uyapSorgu(sorgu: KurumSorgulamaCevap) {
    // TODO: Array olarak mı gelecek tespit edilmeli
    this.localValue.sirket_adi = sorgu[0].kurumAdi;
    this.localValue.vergi_no = sorgu[0].vergiNo;
    this.localValue.ticaret_sicil_no = sorgu[0].ticaretSicilNo;
    this.input();
  }

  load() {
    this.$emit('load');
  }

  async save() {
    this.formWrapper.onFly = true;
    if (this.localValue.id) {
      await this.$http.put('/api/v1/kurum/' + this.localValue.id, this.localValue);
      for (let i = 0; i < this.localValue.adresler.length; i++) {
        if (this.localValue.adresler[i].id)
          await this.$http.put('/api/v1/adres/' + this.localValue.adresler[i].id, this.localValue.adresler[i]);
        else
          await this.$http.post('/api/v1/kurum/' + this.localValue.id + '/adres', this.localValue.adresler[i]);
      }
      this.formWrapper.onFly = false;
      this.$emit('onSuccess');
    } else {
      let response = await this.$http.post('/api/v1/kurum/', this.localValue);
      for (let i = 0; i < this.localValue.adresler.length; i++) {
        await this.$http.post('/api/v1/kurum/' + response.data.id + '/adres', this.localValue.adresler[i]);
      }
      this.formWrapper.onFly = false;
      this.$emit('onSuccess');
    }
  }
}
