

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {PersonelEntity} from "@/entity/PersonelEntity";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import AvukatPicker from "@/components/pickers/AvukatPicker.vue";
import VekaletAvukatForm from "@/components/forms/VekaletAvukatForm.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import {AvukatEntity} from "@/entity/AvukatEntity";
import {ProgramPaths} from "@/enum/ProgramPaths";

@Component({
  components: {FormDialog, VekaletAvukatForm, AvukatPicker, DeleteDialog}
})

export default class AvukatList extends Vue {
  @Prop({default: []}) items!: Array<AvukatEntity>;

  localItems: Array<AvukatEntity> = this.items;
  headers = [
    {text: "Adı", align: "start", sortable: false, value: "ad"},
    {text: "TC Kimlik No", value: "turkish_identification_number"},
    {text: "İşlemler", value: "actions", width: 50, sortable: false, align: 'end'}

  ];

  @Watch("items")
  onItemsChange() {
    this.localItems = this.items;
  }

  load() {
    this.$emit("load");
  }

  detay(item: PersonelEntity) {
    let activeProcess = {id: this.$route.params.id, key: 'avukat-detay'}
    this.$store.commit('setActiveProcess', activeProcess);
    this.$router.push(ProgramPaths.ofispro + '/personel/' + item.id?.toString());
  }
}
