import {KisiKurumEntity} from "@/entity/KisiKurumEntity";
import {Cinsiyet} from "@/enum/Cinsiyet";
import {IlEntity} from "@/entity/IlEntity";
import {KurumEntity} from './KurumEntity';
import {KimlikBelgesiEntity} from "@/entity/KimlikBelgesiEntity";
import {NufusCuzdaniEntity} from "@/entity/NufusCuzdaniEntity";
import {AvukatKimlikKartiEntity} from "@/entity/AvukatKimlikKartiEntity";
import {PasaportEntity} from "@/entity/PasaportEntity";
import {EhliyetEntity} from "@/entity/EhliyetEntity";
import {FileEntity} from "@/entity/FileEntity";

export class KisiEntity extends KisiKurumEntity {
    ad!: string;
    soyad!: string;
    tc_kimlik_no!: string;
    turkish_identification_number !: string;
    kimlik_no!: string;
    yabanci_uyruklu_mu: boolean = false;
    uyruk !: string;
    dogum_yeri!: IlEntity;
    dogum_yeri_id !: number;
    dogum_tarihi!: Date;
    cinsiyet!: Cinsiyet;
    nufus_cuzdani!: NufusCuzdaniEntity;
    avukat_kimlik_karti!: AvukatKimlikKartiEntity;
    ortakOlduguKurumlar: Array<KurumEntity> = [];
    kimlikBelgeleri: Array<KimlikBelgesiEntity> = [];
    pasaport!: PasaportEntity;
    ehliyet!: EhliyetEntity;
    file: Array<FileEntity> = [];
    uyap_kisi_kurum_id!: number;
}
