
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import VekaletForm from "@/components/forms/VekaletForm.vue"

@Component({components: {FormDialog, VekaletForm}})
export default class VekaletInfo extends Mixins(ObjectInputMixin) {
  load() {
    this.$emit("load")
  }
}
