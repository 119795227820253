

import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import KisiTipiPicker from "@/components/pickers/KisiTipiPicker.vue";
import KisiForm from "@/components/forms/KisiForm.vue";
import KurumForm from "@/components/forms/KurumForm.vue";

@Component({
  components: {KurumForm, KisiForm, KisiTipiPicker,}
})
export default class KisiKurumForm extends Mixins(ObjectInputMixin) {
  @Prop() searched!: boolean;
  @Prop() hideButton!: any;
  @Prop() existings!: Array<KisiEntity | KurumEntity>
  @Prop() muvekkilMi !: boolean;
  @Prop() isAddressEmpty !: boolean;

  searchItems: Array<KisiEntity | KurumEntity> = [];
  selectedItem: KisiEntity | KurumEntity = new KisiEntity() || new KurumEntity();
  searchLabel: string = "";
  searchText: string = "";

  @Watch('value')
  protected onValueChange() {
    this.localValue = this.value;
  }

  get sahisMi(): boolean {
    if (this.localValue.kisi_kurum_turu) {
      return this.localValue.kisi_kurum_turu == KisiKurumTuru.sahis;
    }
    return !!this.localValue.kisi;
  }

  get sirketMi(): boolean {
    if (this.localValue.kisi_kurum_turu) {
      return this.localValue.kisi_kurum_turu == KisiKurumTuru.sirket;
    }
    return !!this.localValue.kurum;
  }

  get isHideButton() {
    return this.hideButton || this.hideButton === "";
  }

  vekiller(){
    this.$emit('vekiller')
  }

  mounted() {
    if (!this.value) {
      this.onKisiKurumTuruChange();
    } else {
      this.input();
    }
  }

  @Watch('searchText')
  onSearchTextChange() {
    this.search();
  }

  load() {
    this.$emit("load");
  }

  onKisiKurumTuruChange() {
    delete this.localValue.kisi;
    delete this.localValue.kurum;
    if (this.sahisMi) {
      this.localValue.kisi = new KisiEntity();
      this.searchLabel = "TC Kimlik No veya İsim";
    } else if (this.localValue.kisi_kurum_turu == KisiKurumTuru.sirket) {
      this.localValue.kurum = new KurumEntity();
      this.searchLabel = "Vergi No, Sicil No, Mersis No veya İsim";
    }
    this.input();
  }

  onKisiKurumChange() {
    if (this.sahisMi) {
      this.localValue.kisi = this.selectedItem;

      if (this.localValue.kisi.id === 0) {
        this.localValue.kisi.ad = "";
        this.clearSearchForm();
      } else {
        const action = `/api/v1/kisi/${this.localValue.kisi.id}`;
        this.$http.get(action)
            .then((response) => {
              this.localValue.kisi = response.data.kisi;
              this.localValue.kisi.borcluVekilleri = response.data.borcluVekilleri || [];
              this.$emit('selected', response.data);
              this.clearSearchForm();
              this.input();
            })
            .catch(error => {
              console.error("Kişi bilgileri alınırken hata oluştu:", error);
            });
      }

    } else if (this.sirketMi) {
      this.localValue.kurum = this.selectedItem;

      if (this.localValue.kurum.id === 0) {
        this.localValue.kurum.sirket_adi = "";
        this.clearSearchForm();
      } else {
        const action = `/api/v1/kurum/${this.localValue.kurum.id}`;
        this.$http.get(action)
            .then((response) => {
              this.localValue.kurum = response.data;
              this.$emit('selected', response.data);
              this.clearSearchForm();
              this.input();
            })
            .catch(error => {
              console.error("Kurum bilgileri alınırken hata oluştu:", error);
            });
      }
    }
  }


  clearSearchForm() {
    this.$emit('change');
    this.selectedItem = new KisiEntity();
    this.searchText = "";
  }

  search() {
    let action = "";
    if (this.sahisMi) {
      action = '/api/v1/kisi/filter?keyword=' + this.searchText;
    } else if (this.sirketMi) {
      action = '/api/v1/kurum/filter?keyword=' + this.searchText;
    }
    if (action != "") {
      this.$http.get(action).then((response) => {
        this.searchItems = response.data;
        this.excludeExistings();
        this.searchItems = this.searchItems.filter((item: any) => {
          if (this.sahisMi) {
            this.searchItems = this.searchItems.filter((item:any) => {
              const borcluAd = item.ad;
              return borcluAd.toLowerCase().startsWith(this.searchText.toLowerCase());
            });

            this.searchItems = this.searchItems.filter((item:any) => {
              const borcluSoyad = item.soyad;
              return borcluSoyad.toLowerCase().startsWith(this.searchText.toLowerCase());
            });
          } else if (this.sirketMi) {
            return item.sirket_adi.toLowerCase().startsWith(this.searchText.toLowerCase());
          }
          return true;
        });

        this.searchItems.push({
          ...new KisiEntity(),
          ...new KurumEntity(),
          id: 0,
          ad: "Yeni Kişi",
          soyad: "",
          sirket_adi: "Yeni Kurum"
        });
        this.searchItems.reverse();
      });
    }
  }

  excludeExistings() {
    this.searchItems = this.searchItems.filter((item: KisiEntity | KurumEntity) => {
      return this.existings.filter((ex: KisiEntity | KurumEntity) => {
        return ex.id === item.id;
      }).length === 0;
    })
  }
};
