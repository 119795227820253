
import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";

@Component({
  components: { DatabasePicker },
})

export default class DokumanTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop({required: true}) action!: string;
  @Prop() returnId!: boolean;
  @Prop() rules!: string;
  @Ref('databasePicker') readonly databasePicker!: DatabasePicker;

  load() {
    this.databasePicker.load();
  }
}
