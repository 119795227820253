

import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {BankaHesabiTipi} from "@/enum/BankaHesabiTipi";

@Component({
  components: {}
})
export default class BankaHesabiTipiPicker extends Mixins(SimpleInputMixin) {
  bankaHesabiTipi = BankaHesabiTipi;
}
