
import {Component, Mixins, Prop} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  directives: {
    mask
  },
})
export default class YabanciKimlikNumarasi extends Mixins(SimpleInputMixin) {
  @Prop() required: any;

  validate() {
    this.$emit("value", this.localValue);
  }

  get isRequired() {
    return this.required || this.required === "";
  }

  mask = "###########";
  rules = [
    (value: any) => {
      return (!this.isRequired || !!value) || "Lütfen bu alanı doldurun."
    },
    (value: any) => {
      return (!this.value || value == "" || value?.toString().length == 11) || "11 karakter olmalıdır.";
    },
    (value: any) => {
      return (!this.value || value === "" || this.$helper.yabanciKimlikNoValidate(value) === true) || "Yabancı Kimlik No doğru değil."
    },
  ];

};
