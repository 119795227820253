

import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DokumanForm from "@/components/forms/DokumanForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {FileEntity} from "@/entity/FileEntity";

@Component({
  components: {DeleteDialog, DokumanForm, FormDialog}
})

export default class DokumanList extends Mixins(SimpleInputMixin) {
  @Prop({required: true}) action!: string;
  @Prop({required: true}) turAction!: string;
  @Prop()height!:string
  @Prop({default:{'items-per-page-options': [3,2]}})footerProps!:any

  localValue!: Array<FileEntity>;
  headers = [
    {text: "Tür", value: "dokuman_turu", align: "start"},
    {text: "Açıklama", value: "aciklama", align: "start", sortable: false},
    {text: "Dosya Adı", value: "isim", align: "start", sortable: false},
    {text: "İşlemler", value: "actions", width: 100, align: 'end', sortable: false}
  ];

  load() {
    this.$emit('load');
  }

  download(dokuman: FileEntity) {
    if (dokuman.uyap_evrak_id) {
      this.$uyap.EvrakIndir().run({evrakId: dokuman.uyap_evrak_id,dosyaId:'',yargiTuru:1});
    } else {
      this.$helper.download(dokuman.name, '/api/v1/file/download/' + dokuman.file_path.replace('uploads/', ''))
    }
  }

  isAuthorized(keySuffix: string) {
    let ap = [...this.$store.state.activeProcesses].reverse();
    let authKey = null;

    if (keySuffix[0] !== '-')
      keySuffix = '-' + keySuffix;

    if (ap[0].key === "takip-duzenle" || ap[0].key === "takip-ac")
      authKey = "takip-dokuman" + keySuffix;
    else if (ap[0].key === "borclu-detay")
      authKey = "borclu-dokuman" + keySuffix;
    else if (ap[0].key === "alacakli-detay")
      authKey = "muvekkil-dokuman" + keySuffix;
    else if (ap[0].key === "vekalet-detay")
      authKey = "vekalet-dokuman" + keySuffix;
    else if (ap[0].key === "muvekkil-detay")
      authKey = "muvekkil-dokuman" + keySuffix;

    if (!authKey) return false;
    return this.$helper.isAuthorized(authKey)
  }
}
