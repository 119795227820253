
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";

@Component({
  components: {DatabasePicker}

})
export default class YabanciUyrukSehirInput extends Mixins(SimpleInputMixin){
  @Prop() rules!: any;
  @Prop() label!: any;
  @Prop() color!: any;


  sehirInput=''
}
