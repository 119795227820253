

import {Component, Mixins} from "vue-property-decorator";
import PickerInputMixin from "@/mixins/PickerInputMixin.vue";

@Component({
  components: {}
})
export default class AvukatPicker extends Mixins(PickerInputMixin) {

  headers = [
    {text: "Adı", align: "start", sortable: false, value: "ad"},
    {text: "Personel Türü", align: "start", sortable: false, value: "roles.0.name"},
  ];

  mounted() {
    this.load();
  }

  load() {
    this.$http.get("/api/v1/personel/filter?type=avukat").then(response => {
      this.items = response.data;
    });
  }
}

