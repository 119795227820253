
import {Component, Mixins, Prop} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IlEntity} from "@/entity/IlEntity";
import {NoterEntity} from "@/entity/NoterEntity";

@Component({
  components: {DatabasePicker},
})
export default class NoterPicker extends Mixins(SimpleInputMixin) {
  @Prop() LabelNew!: string | null;
  @Prop() returnId!: string | boolean;
  @Prop() returnObject!: string | boolean;
  @Prop() rules !: any;

  items: Array<object> = [];

  get label(){
    if (this.LabelNew!=null){
      return this.LabelNew;
    }else{
      return "Noter";
    }
  }

  get isReturnId() {
    return this.returnId || this.returnId === "";
  }

  get isReturnObject() {
    return this.returnObject || this.returnObject === "";
  }

  onNoterChange(v: NoterEntity) {
    this.$emit('noter-adi', v.isim);
    this.input()
  }

  mounted() {
    this.load();
  }

  load() {
    if (this.isReturnObject) {
      this.$http
          .get("/api/v1/noter")
          .then((response: any) => {
            this.items = response.data;
          });
    }
  }
}
