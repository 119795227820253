
import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {FileEntity} from "@/entity/FileEntity";
import DokumanTuruPicker from "@/components/pickers/DokumanTuruPicker.vue";
import Crypto from "crypto";


@Component({
  components: {DokumanTuruPicker, FormWrapper}
})

export default class DokumanForm extends Mixins(ObjectInputMixin) {
  @Prop({required: true}) action!: string;
  @Prop({required: true}) turAction!: string;
  @Ref('dokumanTuruPicker') readonly dokumanTuruPicker!: DokumanTuruPicker;
  @Ref('formWrapper') formWrapper!: FormWrapper;
  localValue!: FileEntity;

  fileRule() {
    if (this.localValue.dosya) {
      let uzanti = this.localValue.dosya.name.split('.').pop();
      if (["udf", "pdf", "jpg", "jpeg", "png", "tif", "tiff", "UDF", "PDF", "JPG", "JPEG", "PNG", "TIF", "TIFF"].indexOf(uzanti) >= 0) {
        if (this.localValue.dosya.size < 10485760)
          return true;
        else
          return "Doküman boyutu 10MB'dan fazla olamaz.";
      } else
        return "Doküman formatı UYAP'a uygun değil."
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  onClose(){
    this.$emit("onClose");

  }

  async save() {
    this.formWrapper.onFly = true;
    let formData = new FormData();
    const Crypto = require('crypto');
    let b64File:any= await this.$helper.convertToBase64(this.localValue.dosya);
    let hash = Crypto.createHash('sha1').update(b64File.split(',')[1]).digest();
    formData.append('aciklama', this.localValue.aciklama);
    formData.append('file', this.localValue.dosya);
    formData.append('dokuman_turu_id', this.localValue.dokuman_turu_id.toString());
    formData.append('hash', hash.toString('hex'));
    if (this.localValue.dokuman_turu_id == 7 && this.localValue.manuel_dokuman_turu) {
      this.$http.post('/api/v1/dokuman-turu', {isim: this.localValue.manuel_dokuman_turu}).then((response) => {
        this.dokumanTuruPicker.load();
        formData.append('dokuman_turu_id', response.data.id);
        this.$http.post(this.action + '/file', formData).then(() => {
          this.$emit('onSuccess');
        });
      }).finally(() => (this.formWrapper.onFly = false));
    } else {
      this.$http.post(this.action + '/file', formData).then(() => {
        this.$emit('onSuccess');
      }).finally(() => (this.formWrapper.onFly = false));
    }
  }
}
