
import {Component, Vue} from "vue-property-decorator";
import {AvukatYetkiliKurumlarItem} from "@/plugins/uyap-plugin/uyap/AvukatYetkiliKurumlar";

@Component({
  components: {}
})
export default class MtsYetkiliKurumSecimi extends Vue {
  items: AvukatYetkiliKurumlarItem[] = [];

  created() {
    this.getYetkiliListe();
  }

  async getYetkiliListe() {
    this.items = await this.$uyap.AvukatYetkiliKurumlar().run({});
  }
}
