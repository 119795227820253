

import {Component, Prop, Watch, Vue, Ref} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import AvukatPicker from "@/components/pickers/AvukatPicker.vue";
import {AvukatEntity} from "@/entity/AvukatEntity";

@Component({
  components: {
    FormWrapper,
    AvukatPicker
  },
})
export default class VekaletAvukatForm extends Vue {
  @Prop() value: any;
  @Ref('formWrapper') formWrapper!: FormWrapper;
  localValue = this.value;
  avukatLength: boolean = false

  @Watch("value")
  onValueChange() {
    this.localValue = this.value;
  }
  onClose(){
    this.$emit("onClose");

  }
  private insertAvukatlar() {
    this.formWrapper.onFly = true;
    let data = {
      avukatlar: this.localValue.map((avukat: AvukatEntity) => avukat.id)
    }
    if (data.avukatlar.length > 0) {
      this.$http.put('/api/v1/vekalet/' + this.$route.params.id + '/avukat', data)
          .then(() => {
            this.avukatLength = false;
            this.$emit("onSuccess");
          }).finally(() => (this.formWrapper.onFly = false));
    } else {
      this.avukatLength = true;
      this.formWrapper.onFly = false;
    }
  }
};
