
import { Component, Mixins, Prop, Vue } from "vue-property-decorator";
import IbanNumarasi from "@/components/inputs/IbanNumarasi.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import BankaPicker from "@/components/pickers/BankaPicker.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import BankaHesabiTipiPicker from "@/components/pickers/BankaHesabiTipiPicker.vue";
import { BankaHesabiTipi } from "@/enum/BankaHesabiTipi";

@Component({
  components: {
    BankaHesabiTipiPicker,
    ParaBirimiPicker,
    BankaPicker,
    IbanNumarasi,
  },
})
export default class BankaBilgileri extends Mixins(ObjectInputMixin) {
  hesapTipiEnum = BankaHesabiTipi;
}
