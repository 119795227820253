export enum SirketTipi
{
    kamu="kamu",
    ozel="ozel"
}

export enum SirketTipiLabel
{
    kamu="Kamu",
    ozel="Özel"
}