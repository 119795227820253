import {AdresTuru} from "@/enum/AdresTuru";
import {IlceEntity} from "@/entity/IlceEntity";
import {IlEntity} from "@/entity/IlEntity";
import {AdresTuruEntity} from "@/entity/AdresTuruEntity";
export class AdresEntity{
    id!:number;
    adres_tur_id!:number;
    sehir_id!:number;
    ilce_id!:number;
    posta_kodu!:number;
    adres!:string;
    created_at!:Date;
    updated_at!:Date;
    deleted_at!:Date;
    pivot!:any;
    adres_turu_id!:number;
    adres_turu!: AdresTuruEntity;
    sehir!:IlEntity;
    ilce!:IlceEntity;
}
