
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {BankaHesabiEntity} from "@/entity/BankaHesabiEntity";
import BankaHesabiForm from "@/components/forms/BankaHesabiForm.vue";
import {VekaletEntity} from "@/entity/VekaletEntity";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import {BankaHesabiSahibi, BankaHesabiSahibiLabel} from "@/enum/BankaHesabiSahibi";
import {BuroEntity} from "@/entity/BuroEntity";
import {AvukatEntity} from "@/entity/AvukatEntity";

@Component({
  components: {BankaHesabiForm, DeleteDialog}
})
export default class VekaletBankaHesabiSec extends Vue {

  @Prop() editable!: boolean;
  @Prop() action!: string;
  @Prop() vekalet!: VekaletEntity;
  @Prop()height!:string
  @Prop({default:{'items-per-page-options': [3,2]}})footerProps!:any
  selected: Array<BankaHesabiEntity> = [];
  bankaHesaplari: Array<BankaHesabiEntity> = [];
  burolar: Array<number> = [];
  bankaHesabiSahibiLabel:any = BankaHesabiSahibiLabel;

  headers = [
    {text: 'Hesap Sahibi', value: 'varsayilan_banka_hesabi_sahibi', width: '80px'},
    {text: 'Hesap Adı', value: 'isim', width: '190px'},
    {text: 'Banka Adı', value: 'banka.isim', width: '140px'},
    {text: 'Iban', value: 'iban', width: '250'},
    {text: 'Hesap No', value: 'hesap_numarasi'},
    {text: 'Para Birimi', value: 'para_birimi.kisa_isim'},

  ]

  @Watch("vekalet.varsayilan_banka_hesabi_id")
  onBankaHesabiChange() {
    if (this.selected.length === 0) {
      this.selected = [this.vekalet.varsayilan_banka_hesabi];
    }
  }

  //İLK GİRİLEN BANKA HESABININ VARSAYILAN GELMESİ
  @Watch("vekalet")
  onVekaletChange() {
    this.bankaHesaplariDoldur();
    if (this.bankaHesaplari && !this.vekalet.varsayilan_banka_hesabi) {
      this.vekalet.varsayilan_banka_hesabi_id = this.bankaHesaplari[0].id
      this.vekalet.varsayilan_banka_hesabi_sahibi = this.bankaHesaplari[0].varsayilan_banka_hesabi_sahibi
      this.selected.push(this.bankaHesaplari[0])
      this.$http
          .put('/api/v1/vekalet/' + this.vekalet.id, this.vekalet)
          .then(() => this.$emit("load"))
    }
  }

  @Watch("vekalet.muvekkiller.length")
  onMuvekkilChange() {
    this.bankaHesaplariDoldur();
  }

  @Watch("vekalet.avukatlar.length")
  onAvukatChange() {
    this.bankaHesaplariDoldur();
  }


  load() {
    this.$emit("load");
  }


  onselectedChange(val: any) {
    this.selected = [val.item];
    if (val.value) {
      this.vekalet.varsayilan_banka_hesabi_id = val.item.id;
      this.vekalet.varsayilan_banka_hesabi_sahibi = val.item.varsayilan_banka_hesabi_sahibi;
      this.$http
          .put('/api/v1/vekalet/' + this.vekalet.id, this.vekalet)
          .then(() => this.load)
    }
  }

  bankaHesaplariDoldur() {
    this.bankaHesaplari = [];
    this.burolar = [];
    for (let i = 0; i < this.vekalet.muvekkiller.length; i++) {
      let kisiKurum: KisiEntity | KurumEntity = this.vekalet.muvekkiller[i].kisi ?? this.vekalet.muvekkiller[i].kurum
      if (kisiKurum != null) {
        kisiKurum.banka_hesaplari.forEach((bankaHesabi: any) => {
          bankaHesabi.varsayilan_banka_hesabi_sahibi = BankaHesabiSahibi.muvekkil;
          this.bankaHesaplari.push(bankaHesabi)
        })
      }
    }

    for (let i = 0; i < this.vekalet.avukatlar.length; i++) {
      let avukat: AvukatEntity = this.vekalet.avukatlar[i];
      this.buroBankaHesabiGetir(avukat.id)
      if (avukat != null) {
        avukat.banka_hesaplari.forEach((bankaHesabi: any) => {
          bankaHesabi.varsayilan_banka_hesabi_sahibi = BankaHesabiSahibi.vekil;
          this.bankaHesaplari.push(bankaHesabi)
        })
      }
    }
  }

  buroBankaHesabiGetir(deger: number) {
    this.$http
        .get("/api/v1/personel/" + deger + "/buro")
        .then((response) => {
          let buro: BuroEntity = response.data
          if (!this.burolar.includes(buro.id)) {
            this.burolar.push(buro.id);
            buro.banka_hesaplari.forEach((bankaHesabi: any) => {
                  bankaHesabi.varsayilan_banka_hesabi_sahibi = BankaHesabiSahibi.vekil;
                  this.bankaHesaplari.push(bankaHesabi)
                }
            )
          }
        })
  }
}
