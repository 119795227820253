import { render, staticRenderFns } from "./CinsiyetPicker.vue?vue&type=template&id=5b594500"
import script from "./CinsiyetPicker.vue?vue&type=script&lang=ts"
export * from "./CinsiyetPicker.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports