
import {Component, Mixins, Prop} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
    directives: {
        mask
    }
})
export default class YevmiyeNumarasi extends Mixins(SimpleInputMixin) {
    @Prop() label !: string;
    @Prop() rules !: string;
    @Prop() hideDetails !: string | boolean;
    @Prop() backgroundColor !: string;
    @Prop({default:"####/######"}) maskStr: any;

    get isHideDetails() {
        return this.hideDetails || this.hideDetails === "";
    }

    validate() {
        this.$emit("value", this.localValue);
    }
}
