
import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
@Component({
  directives: {
    mask
  },
})
export default class HesapNumarasi extends Mixins(SimpleInputMixin){

  mask= "####-###############";

  rules= [
    (value:any) => { return !!value || "Zorunlu." },
  ];
  validate() {
    this.$emit("value", this.localValue);
  }

  @Watch('localValue')
  onLocalValChange(){
    this.input();
  }

};
