

import {Component, Mixins, Prop} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {Cinsiyet, CinsiyetLabel} from "@/enum/Cinsiyet";

@Component({
  components: {EnumPicker}
})
export default class CinsiyetPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() label!:string;
  cinsiyet = Cinsiyet;
  cinsiyetLabel = CinsiyetLabel
}
