
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import AdresForm from "@/components/forms/AdresForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {AdresEntity} from "@/entity/AdresEntity";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {AdresTuruLabel} from "@/enum/AdresTuru";
import BankaHesabiForm from "@/components/forms/BankaHesabiForm.vue";

@Component({
  components: {BankaHesabiForm, FormDialog, AdresForm, DeleteDialog}
})
export default class AdresList extends Mixins(SimpleInputMixin) {
  @Prop({}) items!: Array<AdresEntity>;
  @Prop({}) action!: string;
  @Prop({}) successOnValidate !: any;
  @Prop () yabanciUyruklu!:string|boolean
  @Prop()height!:string
  @Prop()footerProps!:any
  @Prop()rules!:any

  AdresTuruLabel = AdresTuruLabel;
  localValue!: Array<AdresEntity>;

  headers= [
    {text: "Adres", align: "start", sortable: false, value: "adres", class: 'my-header-style'},
    {text: "İşlemler", align: "end", sortable: false, value: "actions", class: 'header-islemler-style'},
  ]

  mounted() {
    if (!this.value) {
      this.localValue = [];
    }
  }
  get isYabanciUyruklu(){
    return this.yabanciUyruklu||this.yabanciUyruklu==''
  }

  @Watch("items")
  onItemsChange() {
    this.localValue = this.items;
  }

  onAdd(item: AdresEntity) {
    if (this.action) {
      this.load();
    } else {
      this.localValue.push(item);
      this.input();
    }
  }

  onUpdate(item: AdresEntity, index: number) {
    let newArray = [...this.localValue];
    newArray[index] = item;
    this.localValue = newArray;
    this.input();
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
    this.input();
  }

  load() {
    this.$emit("load");
  }

  isAuthorized(keySuffix: string) {
    let ap = [...this.$store.state.activeProcesses].reverse();
    let authKey = null;

    if (keySuffix[0] !== '-')
      keySuffix = '-' + keySuffix;

    if (ap[0].key === "takip-ac" || ap[0].key === "takip-duzenle")
      authKey = "takip-ac";
    else if (ap[0].key === "borclu-detay")
      authKey = "borclu-adres-bilgisi" + keySuffix;
    else if (ap[0].key === "alacakli-detay")
      authKey = "muvekkil-adres-bilgisi" + keySuffix;
    else if (ap[0].key === "buro-detay")
      authKey = "buro-adres-bilgisi" + keySuffix;
    else if (ap[0].key === "personel-detay")
      authKey = "calisan-adres-bilgisi" + keySuffix;
    else if (ap[0].key === "muvekkil-detay" || ap[0].key === "vekalet-detay")
      authKey = "muvekkil-adres-bilgisi" + keySuffix;
    else if (ap[0].key === "avukat-detay")
      authKey = "avukat-adres-bilgisi" + keySuffix;

    if (!authKey) return false;
    return this.$helper.isAuthorized(authKey)
  }


}

