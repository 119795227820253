import {AdresEntity} from "@/entity/AdresEntity";
import {IletisimBilgisiEntity} from "@/entity/IletisimBilgisiEntity";
import {BankaHesabiEntity} from "@/entity/BankaHesabiEntity";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";

export type KisiKurum = KisiEntity | KurumEntity;

export abstract class KisiKurumEntity {
    id!: number;
    adresler: Array<AdresEntity> = [];
    varsayilan_adres_id!: number;
    varsayilan_adres!: AdresEntity;
    iletisim_bilgileri: Array<IletisimBilgisiEntity> = [];
    banka_hesaplari: Array<BankaHesabiEntity> = [];
    uyap_kisi_kurum_id!: number;
}